import "bootswatch/dist/vapor/bootstrap.min.css"
import { FunctionalComponent, h } from "preact"
import { Route, Router } from "preact-router"

import EventListPage from "./routes/eventlist"
import SettingsPage from "./routes/settings"
import CreateEventPage from "./routes/createevent"
import EventPage from "./routes/event"
import ScanTicketPage from "./routes/scanticket"
import NotFoundPage from "./routes/notfound"

export const App: FunctionalComponent = () => {
  return (
    <div id="preact_root">
      <Router>
        <Route path="/" component={EventListPage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/event/new" component={CreateEventPage} />
        <Route path="/event/:eventId" component={EventPage} />
        <Route path="/event/:eventId/ticket/new" component={ScanTicketPage} />
        <NotFoundPage default />
      </Router>
    </div>
  )
}
